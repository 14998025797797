<template>
  <v-container id="orders" fluid tag="section">
    <orders-view :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="$t('order.list.empty.title')" :no-result-description="$t('order.list.empty.description')"
                 @refresh="refreshOrders"
    />
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import OrderState from '@/services/order/OrderState';
  import OrdersView from '@/views/components/business/order/OrdersView';

  export default {
    name: 'Orders',
    components: { OrdersView },
    data () {
      return {
        error: '',
        lastUpdate: 0,
        orders: []
      };
    },
    methods: {
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/today/');
          if (Array.isArray(response.data) && !this.$can('read', 'CustomerService')) {
            this.orders = response.data.filter((order) => ![OrderState.created, OrderState.payment, OrderState.review].includes(order.state.state));
          } else {
            this.orders = response.data;
          }
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('order.error'));
        }
      }
    }
  };
</script>
